import { useEffect } from "react"
import styled from "styled-components/macro"
import ReactPortal from "../atoms/ReactPortal"

type ModalProps = {
	children: React.ReactNode
	show: boolean
	onClose: () => void
	id: string
}

const Modal: React.FC<ModalProps> = ({ children, show, onClose, id }) => {
	useEffect(() => {
		const closeOnEscapeKey: (this: HTMLElement, ev: KeyboardEvent) => any = e =>
			e.key === "Escape" ? onClose() : null
		document.body.addEventListener("keydown", closeOnEscapeKey)
		return () => {
			document.body.removeEventListener("keydown", closeOnEscapeKey)
		}
	}, [onClose])
	return (
		<ReactPortal wrapperId={id}>
			<Windowshade show={show} onClick={() => onClose()}>
				{show && (
					<ModalWrapper onClick={e => e.stopPropagation()}>
						<ScrollWrapper>{children}</ScrollWrapper>
					</ModalWrapper>
				)}
			</Windowshade>
		</ReactPortal>
	)
}
const Windowshade = styled.div<{ show: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(181, 223, 172, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5000;
	opacity: ${({ show }) => (show ? 1 : 0)};
	transition: 250ms all ease-in-out;
	pointer-events: ${({ show }) => (show ? "all" : "none")};
`
const ModalWrapper = styled.div`
	width: 75%;
	max-width: 920px;
	height: 75%;
	border: 2px solid black;
	overflow: hidden;
	position: relative;
	background-color: white;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px 0px;
	@media (max-width: 700px) {
		width: 85%;
	}
`
const ScrollWrapper = styled.div`
	overflow: auto;
	max-height: 70vh;
	.content {
		justify-content: center;
		align-items: center;
	}
`

export default Modal
