import styled from "styled-components/macro"

const MiniCard = styled.div<{ hoverBg?: string }>`
	background-color: white;
	border: 1.5px solid black;
	min-height: 50px;
	min-width: 50px;
	max-width: 250px;
	max-height: 100px;
	border-radius: 4px;
	padding: 0px;
	margin: 15px 23px 15px 7px;
	display: flex;
	flex-direction: column;
	transition: all 150ms ease-in-out;
	box-shadow: -10px 10px black;
	h3 {
		margin: 7px;
		margin-bottom: 0px;
	}
	p {
		margin: 7px;
	}
	&:hover,
	:active,
	:focus {
		background-color: ${props => props.hoverBg || "white"};
	}
`

export default MiniCard
