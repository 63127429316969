import { forwardRef } from "react"
import styled, { css } from "styled-components/macro"
type xProps = {
	x?: boolean
}
type NavHamburglarIconProps = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> & xProps

const NavHamburglarIcon = forwardRef<HTMLDivElement, NavHamburglarIconProps>((props, ref) => {
	return (
		<Wrapper {...props} ref={ref}>
			<Bar />
			<Bar />
			<Bar />
			<Bar />
		</Wrapper>
	)
})
const barHeight = 4.5
const Bar = styled.span`
	box-shadow: -1.5px 1.5px black;
	display: block;
	position: absolute;
	height: ${barHeight}px;
	width: 100%;
	background: white;
	border: 1px solid black;
	border-radius: ${barHeight}px;
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: 0.25s ease-in-out;
	padding-bottom: 3px;
	&:nth-child(1) {
		top: 0;
	}
	&:nth-child(2),
	&:nth-child(3) {
		top: ${barHeight * 2}px;
	}
	&:nth-child(4) {
		top: ${barHeight * 4}px;
	}
`
const Wrapper = styled.div<xProps>`
	width: 30px;
	position: relative;

	height: ${barHeight * 5}px;

	margin: auto;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;
	cursor: pointer;
	${({ x }) =>
		x &&
		css`
			${Bar}:nth-child(1), 
        ${Bar}:nth-child(4) {
				top: ${barHeight * 2}px;
				width: 0%;
				border: none;
				left: 50%;
			}
			${Bar}:nth-child(2) {
				transform: rotate(45deg);
				box-shadow: 0px 0px;
			}
			${Bar}:nth-child(3) {
				transform: rotate(-45deg);
				box-shadow: 0px 0px;
			}
		`}
`
export default NavHamburglarIcon
