import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components/macro"
import Subscribe from "../molecules/Subscribe"
import NavHamburglarIcon from "../atoms/NavHamburglarIcon"
import Share from "../molecules/Share"
import BlobImage from "../SVGComponents/BlobImageSVG"
import AnchorLink from "../atoms/AnchorLink"

//need to map an array of items, with the values of name & route

const navArray = [
	{
		label: "home",
		link: "/",
	},
	{
		label: "about",
		link: "/about",
	},
	{
		label: "notes",
		link: "/booknotes",
	},
	{
		label: "projects",
		link: "/projects",
	},
	{
		label: "quotes",
		link: "/quotes",
	},

	{
		label: "wordcandy",
		link: "/wordcandy",
	},
]

const NavBar: React.FC = () => {
	const [display, setDisplay] = useState<boolean>(false)

	return (
		<NavBarWrapper>
			<ImageContainer>
				<AnchorLink href="/">
					<BlobImage />
				</AnchorLink>
			</ImageContainer>
			<SubNavBarWrapper>
				<Subscribe />
				<Share />
				<Nav display={display}>
					<NavHamburglarIcon x={display} onClick={() => setDisplay(!display)} />
					<NavLinkWrapper display={display} onClick={() => setDisplay(!display)}>
						{navArray.map(item => (
							<NavLink onClick={() => setDisplay(!display)} key={item.link} to={item.link}>
								<li>
									<span>{item.label}</span>
								</li>
							</NavLink>
						))}
					</NavLinkWrapper>
				</Nav>
			</SubNavBarWrapper>
		</NavBarWrapper>
	)
}

const NavBarWrapper = styled.div`
	position: sticky;
	background-color: white;
	top: 0;
	height: 75;
	display: flex;
	align-items: center;
	z-index: 12;
`
const SubNavBarWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;
	height: 75px;
`
const NavLinkWrapper = styled.div<{ display: boolean }>`
	width: 75px;
	overflow: hidden;
	position: absolute;
	background-color: white;
	top: 75px;
	height: ${({ display }) => (display ? "40vh" : "0px")};
	max-height: 400px;
	transition: all 350ms ease-in-out;
	box-shadow: inset 0 4px 3px -4px black;
	border-left: 1px solid black;
	border-top-left-radius: 3px;
	border-top-right-radius: 1px;
	border-bottom-left-radius: 3px;
	border-right: 2px solid black;
	border-bottom: ${({ display }) => (display ? "1px solid black" : "none")};
	overflow: auto;
	scrollbar-width: none;
	::-webkit-scrollbar {
		display: none;
	}
	::-webkit-scrollbar-track {
		background: none;
	}

	*::-webkit-scrollbar-thumb {
		display: none;
	}
`

const Nav = styled.div<{ display: boolean }>`
	min-width: 75px;
	a {
		text-decoration: none;
		color: black;
	}
	li {
		list-style: none;
		position: relative;
		text-align: center;
		margin: 0.8rem 0;
	}
	.active {
		span {
			border-bottom: 2px solid black;
		}
	}
`
const ImageContainer = styled.div`
	width: 75px;
	height: 75px;

	display: flex;
`

export default NavBar
