import styled from "styled-components/macro"

const SkewContent = styled.div<{ angle?: string }>`
	display: flex;
	margin: 10px 0px;
	> * {
		rotate: ${({ angle }) => "z " + angle || "z -10deg"};
	}
`

export default SkewContent
