import { forwardRef } from "react"
import styled from "styled-components/macro"
import ArrowButton from "../atoms/ArrowButton"

interface InputWithSubmitProps extends React.InputHTMLAttributes<HTMLInputElement> {
	isLoading?: boolean
}

const InputWithSubmit = forwardRef<HTMLInputElement, React.PropsWithChildren<InputWithSubmitProps>>(
	({ isLoading, ...props }, ref) => {
		return (
			<Wrapper>
				<Input {...props} ref={ref} />
				<ArrowButton />
			</Wrapper>
		)
	},
)

const Wrapper = styled.div`
	border: 1.5px solid black;
	margin: 10px 0px;
	height: 50px;
	width: 60%;
	min-width: 150px;
	max-width: 300px;
	border-radius: 10px;
	box-shadow: -3px 3px black;
	cursor: pointer;
	display: flex;

	@media (max-width: 700px) {
		width: 90%;
	}
`

const Input = styled.input`
	flex: 1;
	min-width: 0px;
	text-emphasis-style: bold;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border: 0px;
	padding: 10px;
	&:focus {
		outline: none;
	}
`

export default InputWithSubmit
