import React from "react"
import { DropdownWrapper, StyledSelect, StyledOption, StyledLabel } from "../styles/AdminPageStyles"

export function Dropdown(props: any) {
	return (
		<DropdownWrapper action={props.action} onChange={props.onChange}>
			<StyledLabel htmlFor="entries">{props.formLabel}</StyledLabel>
			<StyledSelect id="entries" name="entries">
				{props.children}
			</StyledSelect>
		</DropdownWrapper>
	)
}

export function Option(props: any) {
	return <StyledOption selected={props.selected}>{props.value}</StyledOption>
}
