import styled from "styled-components/macro"
import BookNote from "../types/BookNote"
import BackArrow from "../atoms/BackArrow"
import ToggleSlider from "../molecules/ToggleSlider"
import StickyHeaderWrapper from "../atoms/StickyHeaderWrapper"
import { useState } from "react"
import Notes from "../molecules/NotesComponent"
import TOC from "../molecules/TOCComponent"
import More from "../molecules/MoreComponent"

type BookNoteProps = {
	item: BookNote
}

const BookNotePage: React.FC<BookNoteProps> = ({ item }) => {
	const [selectedIndex, setSelectedIndex] = useState(0)

	const content = [
		{
			hName: "NOTES",
			content: <Notes item={item} />,
		},
		{
			hName: "T.o.C.",
			content: <TOC item={item} />,
		},
		{
			hName: "MORE",
			content: <More item={item} />,
		},
	]

	return (
		<Wrapper>
			<ToggleSlider
				selectedIndex={selectedIndex}
				setSelectedIndex={setSelectedIndex}
				content={content}
				title="Journey to the end of the night"
			/>
			<ToggleSliderContent>{content[selectedIndex].content}</ToggleSliderContent>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`
const ToggleSliderContent = styled.div`
	padding: 20px;
`

export default BookNotePage
