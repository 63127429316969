import styled from "styled-components/macro"

const Input = styled.input`
	border: 1.5px solid black;
	margin: 10px;
	padding: 10px;
	width: 50%;
	border-radius: 3px;
	box-shadow: -3px 3px black;
	text-emphasis-style: bold;
	text-transform: uppercase;
	cursor: pointer;
	&:focus {
		outline: none;
	}
`

export default Input
