import React from "react"
import BookNote from "../types/BookNote"

import ExpandingFlipCard from "../atoms/ExpandableFlipCard"
import ExpandableCard from "../atoms/ExpandableCard"
import SectionHeader from "../styled-components/SectionHeader"
import MicroNote from "../styled-components/MicroNote"

type BookNoteProps = {
	item: BookNote
}

const Notes: React.FC<BookNoteProps> = ({ item }) => {
	return (
		<div>
			<SectionHeader> I'm a title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>
			<ExpandingFlipCard header="I'm a quote!" />

			<SectionHeader> I'm another title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>

			<MicroNote>I'm a note from a book !</MicroNote>
			<ExpandableCard header="I'm a cool phrase!" />
			<SectionHeader> I'm another title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>
			<SectionHeader> I'm a title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>
			<ExpandingFlipCard header="I'm a quote!" />

			<SectionHeader> I'm another title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>

			<MicroNote>I'm a note from a book !</MicroNote>
			<ExpandableCard header="I'm a cool phrase!" />
			<SectionHeader> I'm another title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>
			<SectionHeader> I'm a title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>
			<ExpandingFlipCard header="I'm a quote!" />

			<SectionHeader> I'm another title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>

			<MicroNote>I'm a note from a book !</MicroNote>
			<ExpandableCard header="I'm a cool phrase!" />
			<SectionHeader> I'm another title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>
			<SectionHeader> I'm a title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>
			<ExpandingFlipCard header="I'm a quote!" />

			<SectionHeader> I'm another title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>

			<MicroNote>I'm a note from a book !</MicroNote>
			<ExpandableCard header="I'm a cool phrase!" />
			<SectionHeader> I'm another title !</SectionHeader>
			<MicroNote>I'm a note from a book !</MicroNote>
		</div>
	)
}

export default Notes
