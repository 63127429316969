import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"

const BackArrow = () => {
	const navigate = useNavigate()
	const goBack = () => {
		navigate(-1)
	}
	return (
		<BackArrowWrapper>
			<BackArrowSVG onClick={goBack} viewBox="0 0 512 512" stroke="black" strokeWidth="15">
				<path d="m44.09 324.1 156.61 100.07a46.787 46.787 0 0 0 48.3 1.64 47.4 47.4 0 0 0 24.89-42.17v-20.59h178.5c29.56 0 53.61-24.22 53.61-54v-104.06c0-29.78-24.05-54-53.61-54h-172.6a5.958 5.958 0 0 1 -5.9-6v-13.63a50.4 50.4 0 0 0 -26.35-44.75 49.725 49.725 0 0 0 -51.22 1.57l-151.92 96.14c-.01 0-.01 0-.02.01a82.985 82.985 0 0 0 -.29 139.77zm229.8 13.89a5.958 5.958 0 0 1 5.9-6h172.6a53.365 53.365 0 0 0 41.48-19.84 41.812 41.812 0 0 1 -41.48 38.9h-178.58c.04-.8.08-13.06.08-13.06zm-255.21-93.58c1.82 19.89 11.69 36.4 28.87 48.07 36.93 25.1 150.4 95 153.19 96.72a46.8 46.8 0 0 0 48.26 1.61 48.227 48.227 0 0 0 12.89-10.22c.86 14.36-5.94 27.86-18.66 34.7a34.965 34.965 0 0 1 -36.07-1.23l-156.6-100.07a70.438 70.438 0 0 1 -32.56-59.84 73.092 73.092 0 0 1 .68-9.74z"></path>
			</BackArrowSVG>
		</BackArrowWrapper>
	)
}

const BackArrowSVG = styled.svg`
	transition: all 200ms ease-in-out;
	fill: #ff9090;
	&:hover,
	&:active {
		cursor: pointer;
		transform: translateX(-12px);
	}
`
const BackArrowWrapper = styled.div`
	display: flex;
	height: 45px;
	width: 45px;
	padding: 5px;
`

export default BackArrow
