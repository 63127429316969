import Project from "../types/Project"

type ProjectProps = {
	item: Project
}

const ProjectPage: React.FC<ProjectProps> = ({ item }) => {
	return (
		<div>
			{item.type}:{item.title}
		</div>
	)
}

export default ProjectPage
