import styled, { css } from "styled-components/macro"

const Card = styled.div`
	background-color: white;
	border: 1.5px solid black;
	height: 325px;
	max-width: 300px;
	min-width: 200px;
	border-radius: 4px;
	padding: 0px;
	margin: 15px 23px 15px 7px;
	display: flex;
	flex-direction: column;
	transition: all 150ms ease-in-out;
	h3 {
		margin: 7px;
		margin-bottom: 0px;
	}
	p {
		margin: 7px;
	}

	@media (min-width: 740px) {
		:last-child {
			margin-right: 0 !important;
		}
		:hover,
		:active {
			transform: translate(4px, -4px);
			cursor: pointer;
			box-shadow: -10px 10px black;
		}
	}
	@media (max-width: 740px) {
		transform: translate(4px, -4px);
		cursor: pointer;
		box-shadow: -10px 10px black;

		:hover,
		:active {
			transform: translate(-4px, 4px);
			box-shadow: 0px 0px;
			cursor: pointer;
		}
		margin: 15px 15px;
	}
`

export default Card
