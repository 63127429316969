import styled from "styled-components"

const CardSubDescription = styled.p`
	color: grey;
	margin-top: 0px;
	text-indent: 7px;
	font-style: italic;
`

export default CardSubDescription
