import React, { ReactNode, useRef, useState } from "react"
import styled from "styled-components/macro"
import ExpandIcon from "./ExpandIcon"

//ExpandableCard is a component that can be used to display a header, a body component is revealed on click. Can be passed a header, icon, background color, & children to make up the body content

type ExpandableCardProps = {
	children?: ReactNode
	header: string
	backgroundColor?: string
	icon?: string
}

const ExpandableCard: React.FC<ExpandableCardProps> = ({
	children,
	header,
	backgroundColor,
	icon,
}) => {
	const [expand, setExpand] = useState(false)

	const ref = useRef<HTMLDivElement>(null)
	console.log({ ref })
	const onExpand = () => {
		setExpand(!expand)
	}
	return (
		<ExandableCardWrapper expand={expand} backgroundColor={backgroundColor}>
			<HeaderWrapper onClick={onExpand}>
				<IconWrapper>{icon || "🍬"}</IconWrapper>
				<Header>{header} </Header>
				<ExpandIconWrapper>
					<ExpandIcon expand={expand} />
				</ExpandIconWrapper>
			</HeaderWrapper>
			<Body expand={expand}>
				{children}
				{children}
			</Body>
		</ExandableCardWrapper>
	)
}

const ExandableCardWrapper = styled.div<{ expand: boolean; backgroundColor?: string }>`
	height: ${({ expand }) => (expand ? "200px" : "50px")};
	max-height: ${({ expand }) => (expand ? "200px" : "50px")};
	transition: all 0.4s ease-in-out;
	overflow: hidden;
	width: 100%;
	margin: 10px 0px;
	border: solid 2px black;
	border-radius: 6px;
	background-color: ${props => props.backgroundColor || "none"};
`

const HeaderWrapper = styled.div`
	width: 100%;
	padding: 10px 15px;
	min-height: 46px;
	display: flex;
	&:hover,
	:active {
		cursor: pointer;
	}
`

const Header = styled.div`
	display: flex;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	align-items: center;
`
const ExpandIconWrapper = styled.div`
	display: flex;
	max-height: 26px;
	flex: 1 1 0%;
	flex-wrap: wrap;
	-webkit-box-pack: end;
	justify-content: flex-end;
	align-items: center;
`
const IconWrapper = styled.div`
	display: flex;
	text-shadow: 1px -3px black;
	margin-right: 10px;
`
const Body = styled.div<{ expand: boolean }>`
	max-height: ${({ expand }) => (expand ? "150px" : "50px")};
	overflow: ${({ expand }) => (expand ? "auto" : "hidden")};
	box-shadow: ${({ expand }) =>
		expand ? "inset 0 4px 3px -4px black, inset 0 -4px 3px -4px black" : "none"};
	transition: all 0.4s ease-in-out;
	padding: 8px 15px;
	border-top-right-radius: 5px;
`

export default ExpandableCard
