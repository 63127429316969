import styled from "styled-components"
import img from "../media/ProfImage.jpeg"

const BlobImageSVG = () => {
	return (
		<StyledSVG xmlns="http://www.w3.org/2000/svg" stroke="#000" viewBox="0 0 1000 1000">
			<defs>
				<clipPath id="b">
					<path
						fill="currentColor"
						d="M775.5 656.5Q681 813 469 867.5T208.5 711q-48.5-211 27-375T523 133q212-39 279.5 164t-27 359.5Z"
					/>
				</clipPath>
				<pattern id="c" width="100%" height="100%" patternUnits="userSpaceOnUse">
					<image href={img} width="100%" height="100%" />
				</pattern>
			</defs>
			<g clipPath="url(#b)">
				<path
					fill="url(#c)"
					d="M775.5 656.5Q681 813 469 867.5T208.5 711q-48.5-211 27-375T523 133q212-39 279.5 164t-27 359.5Z"
				/>
			</g>
		</StyledSVG>
	)
}

const StyledSVG = styled.svg`
	height: 100%;
	width: 100%;
`

export default BlobImageSVG
