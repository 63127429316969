import styled from "styled-components"
import Card from "../atoms/Card"
import Project from "../types/Project"
import CardImage from "../atoms/CardImage"
import CardDescription from "../atoms/CardDescription"
import CardFooter from "../atoms/CardFooter"
import SubTagChip from "../atoms/SubTagChip"
import CardLink from "../atoms/CardLink"

type ProjectsGridProps = {
	items: Project[]
}

const ProjectsGrid: React.FC<ProjectsGridProps> = ({ items }) => {
	return (
		<Wrapper>
			{items.map(item => (
				<Card key={item._id}>
					<CardLink href={`/${item.slug}`}>
						<CardImage src={item.imageURL} />
						<h3>{item.title}</h3>
						<CardDescription>{item.descriptionTeaser}</CardDescription>
						<CardFooter>
							{item.tag.map(item => (
								<SubTagChip>
									<p> {item} </p>
								</SubTagChip>
							))}
						</CardFooter>
					</CardLink>
				</Card>
			))}
		</Wrapper>
	)
}
const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`
export default ProjectsGrid
