import { useParams } from "react-router-dom"
import { tempNotes } from "./BookNotes"
import { tempProjects } from "./Projects"
import BookNotePage from "../organisms/BookNotePage"
import ProjectPage from "../organisms/ProjectPage"

const entries = [...tempNotes, ...tempProjects]
const EntryPage = () => {
	const { slug } = useParams()
	const data = entries.find(entry => entry.slug === slug)
	if (!data) {
		return <h1>404:not found</h1>
	}
	switch (data.type) {
		case "booknote":
			return <BookNotePage item={data} />
		case "project":
			return <ProjectPage item={data} />
	}
}

export default EntryPage
