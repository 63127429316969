import styled from "styled-components"

const AnchorLink = styled.a<{
	color?: string
	textDecoration?: string
	textDecorationColor?: string
	textDecorationThickness?: string
}>`
	color: ${props => props.color || "inherit"};
	text-decoration: ${props => props.textDecoration || "none"};
	text-decoration-color: ${props => props.textDecorationColor || "none"};
	text-decoration-thickness: ${props => props.textDecorationThickness || "none"};
`

export default AnchorLink
