import { ReactNode } from "react"
import styled, { keyframes } from "styled-components"

type InfiniteMarqueeProps = {
	children?: ReactNode
	direction?: string
	speed?: string
}

const InfiniteMarquee: React.FC<InfiniteMarqueeProps> = ({ children, direction, speed }) => {
	return (
		<MarqueeContainer>
			<MarqueeContent direction={direction} speed={speed} className="marquee-content">
				{children}
			</MarqueeContent>
			<MarqueeContent
				direction={direction}
				speed={speed}
				className="marquee-content"
				aria-hidden="true"
			>
				{children}
			</MarqueeContent>
		</MarqueeContainer>
	)
}

const scroll = keyframes`
	from {
	  transform: translateX(0);
	}
	to {
	  transform: translateX(calc(-100% - var(--gap)));
	}
  `

const MarqueeContainer = styled.div`
	--gap: 1px;
	position: relative;
	display: flex;
	overflow: hidden;
	user-select: none;
	gap: var(--gap);
	&:hover .marquee-content {
		cursor: pointer;
		animation-play-state: paused;
	}
	padding: 20px 0px;
`

const MarqueeContent = styled.div<{ direction?: string; speed?: string }>`
	flex-shrink: 0;
	display: flex;
	justify-content: space-around;
	gap: var(--gap);
	min-width: 100%;
	animation: ${scroll} ${({ speed }) => speed || "30s"} linear infinite;
	animation-direction: ${({ direction }) => direction || "normal"};
	@media (prefers-reduced-motion: reduce) {
		animation-play-state: paused !important;
	}
`

export default InfiniteMarquee
