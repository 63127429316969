import styled from "styled-components"

const CardImage = styled.div<{ src: string }>`
	background-image: url(${({ src }) => src});
	background-position: center center;
	background-size: cover;
	border-bottom: 1.5px solid black;
	height: 100px;
`

export default CardImage
