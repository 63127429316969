//this file is used to define the style guide for the entire site, the index
//uses this when it renders the different components in this case we're using
//styled-components plugin ThemeProvider any time we want to change style of an
//element we create a variable for it here (ex. wrapperBG), then we pass it thru
//to the root router under the globalStyle function, then we declare it's type
//in the styled.d.ts, then we will have access to it in all of our components

import React from "react"
import { DefaultTheme, ThemeProvider } from "styled-components/macro"

//<ThemeProvider> wrapper component provides a theme to all React components
//underneath itself via the context API.

const theme: DefaultTheme = {
	colors: {
		wrapperBg: "white",
	},
	timeOfDayColor: () => "black",
}

export default function Theme({ children }: { children: any }) {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

/* the styled components GlobalStyle/Theme relationships between root, theme,
wrapper & index files

Theme.tsx
- here we define our theme constant, we then import DefaultTheme to declare the
  types of all the keys we'll list out
- the export seems like some ts appeasement, but we create a new function called
    Theme, which passes through an array we're calling "children", with a type
    of "any" (make ts happy), this function then returns the <ThemeProvider>
    component with a property called "theme" where we pass thru our const theme,
    & it returns the children of the theme? who's children are these?? lol
- this function Theme that returns the ThemeProvider component with a property w
  our theme constant then gets sent to the index & is wrapped around the router
  component in the root.render logic

index.tsx
-imports the theme component & renders it before the routerProvider component so app has access to it

Root.tsx
-this is where we create the globalStyle constant & pass through the properties from the theme constant in the theme file. 
- we create an object that holds style properties with values like theme.colors.wrapperBg, which target the specific values specified in theme file 
- we then render the GlobalStyle component first after the wrapper in the root component, self closing 

Wrapper.tsx
- this is a styled component for defining the format (padding/margin/etc.) of
  the whole app

styled.d.ts
- this is for creating the DefaultTheme component that holds the types for each
  variable we describe in our theme constant

All other styled components


how does one override on a specific instance/component?


S T Y L E  G U I D O: 
colorzzz: 
borders: 1.5px. 
letter spacing: roomy(2-3px for headers)
overall style: neo brute x ???
show me more design ethos. 
primary cta: statically elevated, press down on click. outlined on hover 
secondary button: see admin page button. boxy, low border radius, box shadow moves deeper into z
tertiary button: ??
typography: headers, body
text styling: 
mouse styling: 
highlight styling: 
link styling: 
Grid Systems
photos: texturization etc. 


*/
