import React from "react"
import WordCandy from "../types/WordCandy"
import WordCandyGrid from "../molecules/WordCandyGrid"

const tempWordCandy: WordCandy[] = [
	{
		phrase: "i'm a phrase1",
		whereFrom: "whereFrom",
		tag: ["tag1", "tag2"],
		example: "I'm an example2",
		description: "im a description2",
		_id: "1",
	},
	{
		phrase: "i'm a phrase2",
		whereFrom: "whereFrom",
		tag: ["tag1", "tag2"],
		example: "I'm an example22",
		description: "im a description22",
		_id: "2",
	},
	{
		phrase: "i'm a phrase3",
		whereFrom: "whereFrom",
		tag: ["tag1", "tag2"],
		example: "I'm an example222",
		description: "im a description222",
		_id: "3",
	},
]

const WordCandyPage: React.FC = () => {
	return (
		<div>
			<h1>WordCandyPage Project</h1>
			<p>a collection of all my favorite WordCandyPage, searchable & filterable</p>
			<p>search by mood buttons: laugh, love, think, learn, crazy, random, inspo </p>
			<p>search all hyper link, has search bar, can search by author, text, era, book, mood</p>
			<p>
				when a mood button is clicked it shows 1 random quote from that mood tag, can then
				swipe/scroll to next or previous
			</p>
			<p>
				on the quote card there's the author, description, quote, mood, & flip button on the front
				side. <br />
				on the back side there's the time quote was said, the source, the book it came from (if
				applicable), the date it was added, and flip button,{" "}
			</p>
			<h1>wordcandy card </h1>

			<WordCandyGrid items={tempWordCandy} />
		</div>
	)
}

export default WordCandyPage
