import styled from "styled-components"

const SubTagChip = styled.div<{ color?: string; textColor?: string }>`
	background-color: ${({ color }) => color || "orange"};
	border-radius: 10px;
	border: 1px solid black;
	margin: 5px;
	text-align: center;
	width: fit-content;
	padding: 1px 8px;
	color: ${({ textColor }) => textColor || "white"};
	p {
		margin: 3px 6px;
		color: white;
	}
`

export default SubTagChip

//this is a tag of a tag
//example, BookNotes is an entry tag, & each booknote has a tag based on the book
