import React, { ReactNode, useState } from "react"
import styled from "styled-components/macro"
import ExpandIcon from "./ExpandIcon"

type ExpandingFlipCardProps = {
	header: string
	icon?: string
	backgroundColor?: string
	children?: ReactNode
}

const ExpandingFlipCard: React.FC<ExpandingFlipCardProps> = ({
	children,
	header,
	icon,
	backgroundColor,
}) => {
	const [expand, setExpand] = useState(false)
	const [flip, setFlip] = useState(false)

	const onExpand = () => {
		setExpand(!expand)
	}
	const onClick = () => {
		setFlip(!flip)
	}

	return (
		<ExpandingFlipCardWrapper expand={expand}>
			<FrontSide flip={flip} expand={expand} backgroundColor={backgroundColor}>
				<HeaderWrapper onClick={onExpand}>
					<IconWrapper>{icon || "📒"}</IconWrapper>
					<Header>{header}</Header>
					<ExpandIconWrapper>
						<ExpandIcon expand={expand} />
					</ExpandIconWrapper>
				</HeaderWrapper>
				<Body expand={expand}>{children}</Body>

				<FlipButton expand={expand} onClick={onClick}>
					flip
				</FlipButton>
			</FrontSide>
			<BackSide backgroundColor={backgroundColor} expand={expand} flip={flip}>
				<HeaderWrapper onClick={onExpand}>
					<IconWrapper>{icon || "📒"}</IconWrapper>
					<Header>{header}(backside contd)</Header>
					<ExpandIconWrapper>
						<ExpandIcon expand={expand} />
					</ExpandIconWrapper>
				</HeaderWrapper>
				<Body expand={expand}>{children}</Body>

				<FlipButton expand={expand} onClick={onClick}>
					flip
				</FlipButton>
			</BackSide>
		</ExpandingFlipCardWrapper>
	)
}

const ExpandingFlipCardWrapper = styled.div<{ expand: boolean }>`
	position: relative;
	margin: 10px 0px;
	height: ${({ expand }) => (expand ? "200px" : "50px")};
	transition: all 0.4s ease-in-out;
`

const FrontSide = styled.div<{ expand: boolean; flip: boolean; backgroundColor?: string }>`
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	position: absolute;
	height: ${({ expand }) => (expand ? "200px" : "50px")};
	max-height: ${({ expand }) => (expand ? "200px" : "50px")};
	overflow: hidden;
	transition: all 0.4s ease-in-out;
	transform: ${({ flip }) =>
		flip ? "perspective(1000px) rotateX(180deg)" : "perspective(1000px) rotateX(0deg)"};
	width: 100%;
	border: solid 2px black;
	border-radius: 6px;
	background-color: ${props => props.backgroundColor || "cyan"};
`
const BackSide = styled(FrontSide)<{ flip: boolean }>`
	transform: ${({ flip }) =>
		flip ? "perspective(1000px) rotateX(0deg)" : "perspective(1000px) rotateX(-180deg)"};
	background-color: ${props => props.backgroundColor || "white"};
	filter: brightness(140%);
`
const FlipButton = styled.div<{ expand: boolean }>`
	position: absolute;
	opacity: ${({ expand }) => (expand ? "1" : "0")};
	visibility: ${({ expand }) => (expand ? "visible" : "hidden")};
	transition: ${({ expand }) =>
		expand
			? "visibility .4s ease-in-out .4s, opacity .6s ease-in-out .4s"
			: "visibility .2s ease-in-out, opacity .2s ease-in-out"};
	bottom: 0px;
	right: 0px;
	padding: 2px 4px;
	border-bottom-right-radius: 4px;
	border-top-left-radius: 4px;
	border: none;
	border-top: solid 2px black;
	border-left: solid 2px black;
	background-color: beige;
	z-index: 1;
	&:hover,
	:active {
		background-color: lightsalmon;
		cursor: pointer;
	}
`

const HeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	min-height: 46px;
	max-height: 50px;
	padding: 10px 15px;
	&:hover,
	:active {
		cursor: pointer;
	}
`
const Header = styled.div`
	display: flex;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	align-items: center;
`
const ExpandIconWrapper = styled.div`
	display: flex;
	max-height: 26px;
	flex: 1 1 0%;
	flex-wrap: wrap;
	-webkit-box-pack: end;
	justify-content: flex-end;
	align-items: center;
`
const IconWrapper = styled.div`
	display: flex;
	text-shadow: 1px -3px black;
	margin-right: 10px;
`

const Body = styled.div<{ expand: boolean }>`
	max-height: ${({ expand }) => (expand ? "150px" : "50px")};
	overflow: ${({ expand }) => (expand ? "auto" : "hidden")};
	box-shadow: ${({ expand }) =>
		expand ? "inset 0 4px 3px -4px black, inset 0 -4px 3px -4px black" : "none"};
	transition: all 0.4s ease-in-out;
	padding: 8px 15px;
	border-top-right-radius: 5px;
`

export default ExpandingFlipCard
