import React, { useState } from "react"
import styled from "styled-components"

interface ToggleSliderProps {
	backgroundColor?: string
	content: Array<{ hName: string; content: JSX.Element | string }>
}

const ToggleSliderV1: React.FC<ToggleSliderProps> = ({ backgroundColor, content }) => {
	const [selectedIndex, setSelectedIndex] = useState(0)

	return (
		<ToggleSliderWrapper>
			<ToggleContainer>
				{content.map((item, index) => {
					return (
						<ToggleContent
							key={item.hName}
							backgroundColor={backgroundColor}
							onClick={() => setSelectedIndex(index)}
							className={selectedIndex === index ? "selected" : ""}
						>
							{item.hName}
						</ToggleContent>
					)
				})}
			</ToggleContainer>
			<ContentContainer>{content[selectedIndex].content}</ContentContainer>
		</ToggleSliderWrapper>
	)
}
const border = "2px solid black"

const ToggleContent = styled.div<{
	backgroundColor?: string
}>`
	flex: 1;
	text-align: center;
	border-radius: 10px;
	padding: 8px;
	letter-spacing: 2px;
	cursor: pointer;
	:hover,
	&.selected,
	:active {
		background-color: ${props => props.backgroundColor || "pink"};
		border-left: ${border};
		border-right: ${border};

		&:first-child {
			border-left: none;
		}
		&:last-child {
			border-right: none;
		}
	}
`

const ContentContainer = styled.div`
	padding: 6px;
	margin: 8px;
`
const ToggleSliderWrapper = styled.div`
	margin: 8px;
`
const ToggleContainer = styled.div`
	display: flex;
	max-width: 600px;
	background-color: white;
	border-radius: 12px;
	border: 2px solid black;
	margin: 0px 0px;
`

export default ToggleSliderV1
