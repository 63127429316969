import styled from "styled-components/macro"

import ToggleSliderV1 from "../molecules/ToggleSliderV1"
import BlobImage from "../SVGComponents/BlobImageSVG"

const Sandbox: React.FC = () => {
	const content = [
		{
			hName: "NOTES",
			content: <Container1 />,
		},
		{
			hName: "T.o.C.",
			content: <Container1 />,
		},
		{
			hName: "MORE",
			content: <Container1 />,
		},
	]

	return (
		<Container>
			<h1>sandbox</h1>
			<p>this is a sandboxzzzzzzzzzzzzzzzzzzfsdfsdfasdfasdfasfasfasdfadsf</p>
			<BlobImageContainer>
				<BlobImage />
			</BlobImageContainer>

			<ToggleSliderV1 content={content} />

			<br />
		</Container>
	)
}

const Container1 = styled.div`
	height: 100px;
	width: 100px;
	border: 1px solid blue;
`
const Container = styled.div``

const BlobImageContainer = styled.div`
	height: 200px;
	width: 200px;
`

export default Sandbox
