import styled from "styled-components"
import Quote from "../types/Quote"

import ExpandingFlipCard from "../atoms/ExpandableFlipCard"

type QuotesGridProps = {
	items: Quote[]
}

const QuotesGrid: React.FC<QuotesGridProps> = ({ items }) => {
	return (
		<QuoteCardWrapper>
			{items.map((item, index) => (
				<ExpandingFlipCard header={item.title} icon="📒" backgroundColor="lightgreen">
					<div>
						{" "}
						this div <br />
						then this one
						<br />
						and this one <br /> weeeeeeee eeee eee this div <br />
						then this one
						<br />
						and this one <br /> weeeeeeee eeee eee this div <br />
						then this one
						<br />
						and this one <br /> weeeeeeee eeee eee this div <br />
						then this one
						<br />
						and this one <br /> weeeeeeee eeee eee
					</div>
				</ExpandingFlipCard>
			))}
		</QuoteCardWrapper>
	)
}

const QuoteCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export default QuotesGrid
