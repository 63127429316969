import React from "react"
import Gazette from "../types/Gazette"

const Gazettes: React.FC = () => {
	return (
		<div>
			<h2>this is about page, heehe</h2>
			<p>
				whole page grid, pics on right side, multiple sub- grid shadows over blanket divs, different
				color lattices, timeline for context, what i do & why, masonry style grid on top of a
				lattice, yup/nope, wit & wonder, as always, other things
			</p>
		</div>
	)
}

export default Gazettes
