import styled from "styled-components"
import Card from "../atoms/Card"
import BookNote from "../types/BookNote"
import SubTagChip from "../atoms/SubTagChip"
import CardFooter from "../atoms/CardFooter"
import CardSubDescription from "../atoms/CardSubDescription"
import CardDescription from "../atoms/CardDescription"
import CardImage from "../atoms/CardImage"
import CardLink from "../atoms/CardLink"
import { useCallback, useEffect, useState } from "react"

type BookNotesGridProps = {
	items: BookNote[]
}

const BookNotesGrid: React.FC<BookNotesGridProps> = ({ items }) => {
	return (
		<Wrapper>
			{items.map(item => (
				<Card key={item._id}>
					<CardLink href={`/${item.slug}`}>
						<CardImage src={item.imageURL} />
						<h3>{item.title}</h3>
						<CardSubDescription>{item.author}</CardSubDescription>
						<CardDescription>{item.descriptionTeaser}</CardDescription>
						<CardFooter>
							<p>
								<b>{item.rating}</b>/10
							</p>
							<ChipWrapper>
								{item.tag.map(item => (
									<SubTagChip key={item.label} color={item.color}>
										<p> {item.label}</p>
									</SubTagChip>
								))}
							</ChipWrapper>
						</CardFooter>
					</CardLink>
				</Card>
			))}
		</Wrapper>
	)
}

const ChipWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	justify-content: flex-end;
`
const Wrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`

export default BookNotesGrid
