import React from "react"
import BookNote from "../types/BookNote"
import styled from "styled-components"

type BookNoteProps = {
	item: BookNote
}
const More: React.FC<BookNoteProps> = ({ item }) => {
	const tableData = [
		{
			author: "Louis Ferdinand Celine",
			rating: "7/10",
			ISBN: "14170840238",
			datePosted: "datePosted",
			tags: ["tag1", "tag2"],
			link: "I'm a link",
		},
	]
	return (
		<MoreWrapper>
			<Table>
				<thead>
					<StyledTableHeaderRow>
						<StyledTableHeader colSpan={4}> more Tchotskis</StyledTableHeader>
					</StyledTableHeaderRow>
				</thead>

				{tableData.map((data, index) => (
					<tbody>
						<StyledTableRow>
							<TableCellLabel> Author </TableCellLabel>
							<TableCellData> {data.author}</TableCellData>
							<TableCellLabel> date posted </TableCellLabel>
							<TableCellData> {data.datePosted}</TableCellData>
						</StyledTableRow>
						<StyledTableRow>
							<TableCellLabel> Rating </TableCellLabel>
							<TableCellData> {data.rating}</TableCellData>
							<TableCellLabel> tags </TableCellLabel>
							<TableCellData> {data.tags}</TableCellData>
						</StyledTableRow>
						<StyledTableRow>
							<TableCellLabel> ISBN </TableCellLabel>
							<TableCellData> {data.ISBN}</TableCellData>
							<TableCellLabel> link </TableCellLabel>
							<TableCellData> {data.link}</TableCellData>
						</StyledTableRow>
					</tbody>
				))}
			</Table>
			probably a table of sorts that has 4(?) columns with key values & attributes
		</MoreWrapper>
	)
}

const MoreWrapper = styled.div`
	width: 100%;
`
const TableCellLabel = styled.td`
	border: 1px solid #ddd;
	padding: 10px;
	text-transform: bold;
	font-weight: bold;
	text-align: left;
	text-transform: uppercase;
`
const TableCellData = styled.td`
	border: 1px solid #ddd;
	padding: 10px;
	text-align: left;
`
const StyledTableRow = styled.tr``
const StyledTableHeaderRow = styled.tr`
	padding: 10px;
	background-color: #f2f2f2;
	border: 1px solid black;
`
const StyledTableHeader = styled.th`
	padding: 10px;
	background-color: #f2f2f2;
	border: 1px solid black;
`
const Table = styled.table`
	max-width: 300px;
	border-collapse: collapse;
`

export default More
