import React from "react"
import styled from "styled-components/macro"
import Wrapper from "../atoms/Wrapper"
import UmbrellaHatSVG from "../SVGComponents/UmbrellaHatSVG"
import DoubleBifocalsSVG from "../SVGComponents/DoubleBifocalsSVG"
import BigHatSVG from "../SVGComponents/BigHatSVG"
import EatingSomethingSVG from "../SVGComponents/EatingSomethingSVG"
import InfiniteMarquee from "../molecules/InfiniteMarquee"
import SubTagChip from "../atoms/SubTagChip"
import SkewContent from "../atoms/SkewContent"
import MiniCard from "../atoms/MiniCard"
import CardDescription from "../atoms/CardDescription"
import AreYouACop from "../atoms/AreYouACop"

const About: React.FC = () => {
	const yes = [
		{ icon: "icon", content: "this thing I love", color: "" },
		{ icon: "icon", content: "this thing I love", color: "" },
		{ icon: "icon", content: "this ", color: "" },
		{ icon: "icon", content: "I love", color: "" },
		{ icon: "icon", content: "this thing I love", color: "" },
		{ icon: "icon", content: "this thing I love", color: "" },
		{ icon: "icon", content: "this thing I love", color: "" },
	]

	const no = [
		{ icon: "icon", content: "this thing I hate", color: "" },
		{ icon: "icon", content: "this thing I hate", color: "" },
		{ icon: "icon", content: "this ", color: "" },
		{ icon: "icon", content: "I hateHate", color: "" },
		{ icon: "icon", content: "this thing I hate", color: "" },
		{ icon: "icon", content: "this thing I hate", color: "" },
		{ icon: "icon", content: "this thing I hateHat", color: "" },
	]

	return (
		<Wrapper>
			<Wrapper padding="20px">
				<Container>
					<ContainerTwo>
						<CopyContainer>
							<Header>hi</Header>
							welcome to rick's website
							<AreYouACop />
							hmmm, okay good start.
							<p>& i love design</p>
							<p>rick is that</p>
							<p></p>
							<p>.</p>
						</CopyContainer>
						<SVGImageContainer>
							<DoubleBifocalsSVG />
						</SVGImageContainer>
						<CopyContainer>
							<Header>hi, welcome to rick's website</Header>
							<p>rick is this</p>
							<p>rick is that</p>
							<p></p>
							<p>.</p>
							<MiniCard>
								<CardDescription>wee</CardDescription>
							</MiniCard>
						</CopyContainer>
						<SVGImageContainer>
							<EatingSomethingSVG />
						</SVGImageContainer>
					</ContainerTwo>

					<ContainerTwo>
						<SVGImageContainer>
							<UmbrellaHatSVG />
						</SVGImageContainer>
						<CopyContainer>
							<Header>hi, welcome to rick's website</Header>
							<p>rick is this</p>
							<p>rick is that</p>
							<p></p>
							<p>.</p>
							<MiniCard>
								<CardDescription>wee</CardDescription>
							</MiniCard>
						</CopyContainer>
						<SVGImageContainer>
							<BigHatSVG />
						</SVGImageContainer>
						<CopyContainer>
							<Header>hi, welcome to rick's website</Header>
							<p>rick is this</p>
							<p>rick is that</p>
							<p></p>
							<p>.</p>
						</CopyContainer>
					</ContainerTwo>
				</Container>
			</Wrapper>

			<SkewContent angle="10deg">
				<h3>yes</h3>
			</SkewContent>

			<InfiniteMarquee direction="reverse">
				{yes.map(item => (
					<SubTagChip color="cyan" textColor="black">
						{item.content}
					</SubTagChip>
				))}
			</InfiniteMarquee>

			<SkewContent angle="-10deg">
				<h3>no</h3>
			</SkewContent>

			<InfiniteMarquee>
				{no.map(item => (
					<SubTagChip color="orange" textColor="black">
						{item.content}
					</SubTagChip>
				))}
			</InfiniteMarquee>
			<div> add touch handlers for swipeysss</div>
			<Header>my work</Header>
			<div> bazerk </div>

			<Header> What I'm doing Now</Header>
			<div>expandable card with big header & link to past nows </div>

			<Header>other ideas</Header>
			<div>lil absolute positioned graphics n shiz i like</div>
		</Wrapper>
	)
}

export default About

const Header = styled.h3``

const CopyContainer = styled.div``
const Container = styled.div`
	display: flex;
	justify-content: space-between;
`
const ContainerTwo = styled.div`
	display: flex;
	flex-direction: column;
`

const SVGImageContainer = styled.div`
	height: 150px;
	width: 150px;
	justify-self: left;
`
