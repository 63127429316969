import { useLocalStorage } from "@uidotdev/usehooks"
import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import { Dropdown, Option } from "../molecules/AdminDropDown"
import BookNoteForm from "../molecules/SubmitBookNote"
import QuoteForm from "../molecules/SubmitQuote"
import PhraseForm from "../molecules/SubmitPhrase"

const AdminPage = () => {
	const [JWT, saveJWT] = useLocalStorage("JWT")
	const [optionValue, setOptionValue] = useState("")
	if (!JWT) {
		return <Navigate to="/" replace />
	}

	const entryFormArray = [
		{
			name: "book note",
			form: <BookNoteForm />,
		},
		{
			name: "quote",
			form: <QuoteForm />,
		},
		{
			name: "phrase",
			form: <PhraseForm />,
		},
	]

	const selectedEntryForm = entryFormArray.find(item => {
		return item.name === optionValue
	})

	const handleSelect = (e: any) => {
		setOptionValue(e.target.value)
	}

	return (
		<div>
			<h1>Submit Entry</h1>
			<Dropdown formLabel="Select EntryType" action="/" onChange={handleSelect}>
				<Option selected value="Click to see entries" />
				<Option value="book note" />
				<Option value="quote" />
				<Option value="phrase" />
			</Dropdown>
			{selectedEntryForm?.form}
		</div>
	)
}

export default AdminPage
