import React from "react"
const BookNoteForm = () => {
	return (
		<form>
			<input type="text" name="title" placeholder="title" />
			<input type="text" name="description" placeholder="description" />
			<input type="text" name="description teaser" placeholder="description teaser" />
			<input type="text" name="isbn" placeholder="isbn" />
			<input type="text" name="purchaseLink" placeholder="purchaseLink" />
			<input type="text" name="rating" placeholder="rating" />
			<input type="text" name="author" placeholder="author" />
			<input type="text" name="tag" placeholder="tag" />
			<input type="text" name="image" placeholder="image"></input>
			<input type="text" name="OGDescription" placeholder="OGDescription"></input>
			<input type="text" name="slug" placeholder="slug"></input>
			<input type="text" name="quotes" placeholder="quotes"></input>{" "}
			<input type="text" name="phrases" placeholder="phrases"></input>
			<input type="text" name="headers" placeholder="headers"></input>
			<button type="submit">submit</button>
		</form>
	)
}
export default BookNoteForm
