import styled from "styled-components"

const CardLink = styled.a`
	display: flex;
	flex-direction: column;
	flex: 1;
	text-decoration: none;
	color: black;
`

export default CardLink
