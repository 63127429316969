import React, { FormEvent, useCallback, useState } from "react"
import styled from "styled-components"
import { useMutation } from "react-query"
import axios from "axios"
import ErrorMessage from "../atoms/ErrorMessage"
import Input from "../atoms/Input"
import Button from "../atoms/Button"
import { Navigate } from "react-router-dom"
import { useLocalStorage } from "@uidotdev/usehooks"

const Secrets: React.FC = () => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [JWT, saveJWT] = useLocalStorage("JWT")

	const {
		mutate: authenticate,
		isError,
		isSuccess,
		isLoading,
	} = useMutation(
		(variables: { email: string; password: string }) =>
			axios.post("/auth/signin", variables, { baseURL: process.env.REACT_APP_API_URL }),
		{
			onSuccess: res => {
				setEmail("")
				setPassword("")
				saveJWT(res.data.token)
			},
		},
	)

	const handleSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault()
			authenticate({ email, password })
		},
		[email, password, authenticate],
	)

	return (
		<SecretWrapper>
			<p>
				wouldnt u like 2 know <br />
				<br />
				<br />
				pervsAnonymous
			</p>
			<Input
				value={email}
				placeholder="u s e r L a m e"
				onChange={e => setEmail(e.target.value)}
				type="email"
				autoComplete="email"
			/>
			<Input
				value={password}
				placeholder="p a s s w o r d"
				onChange={e => setPassword(e.target.value)}
				type="password"
				autoComplete="current-password"
			/>
			<Button type="submit" onClick={handleSubmit}>
				submit
			</Button>
			{isError && <ErrorMessage />}
			{isSuccess && <Navigate to="/admin" />}
		</SecretWrapper>
	)
}

const SecretWrapper = styled.div`
	padding: 24px 20px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	height: 100vh;
`

export default Secrets
