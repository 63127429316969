import styled from "styled-components/macro"

// a plus button that turns into a - when expand prop = true

const ExpandIcon = styled.div<{ expand: boolean }>`
	height: 16px;
	width: 16px;
	position: relative;
	:hover {
		cursor: pointer;
	}
	:before,
	:after {
		content: " ";
		width: 16px;
		height: 4px;
		background-color: #000;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
		opacity: 1;
		border-radius: 2px;
	}
	:before {
		transform: ${({ expand }) =>
			expand ? "translate(-50%, -50%) rotate(0deg)" : "translate(-50%, -50%) rotate(90deg)"};
	}
	:after {
		transform: ${({ expand }) =>
			expand ? "translate(-50%, -50%) rotate(0deg)" : "translate(-50%, -50%)"};
		opacity: ${({ expand }) => (expand ? "0" : "none")};
	}
`

export default ExpandIcon
