import styled, { css } from "styled-components/macro"

const SubscribeIcon = ({ down, ...props }: React.SVGProps<SVGSVGElement> & { down: boolean }) => (
	<SubscribeIconWrapper down={down}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			width={30}
			height={30}
			strokeWidth={0.9}
			stroke="currentColor"
			{...props}
		>
			<path strokeLinecap="butt" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
		</svg>
	</SubscribeIconWrapper>
)

export default SubscribeIcon

const SubscribeIconWrapper = styled.div<{ down: boolean }>`
	border: 1.5px solid black;
	margin: 5px;
	background-color: #ffc7d0;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	height: min-content;
	width: min-content;
	box-shadow: -1.5px 3px black;
	transition: all 150ms ease-in-out;
	${({ down }) =>
		down &&
		css`
			transform: translate(-2px, 4px);
			box-shadow: 0px 0px;
			background-color: #ffc7d0;
		`}
	.modal {
		margin: 10px;
	}
	@media (max-width: 700px) {
		:hover,
		:active {
			transform: translate(-2px, 4px);
			box-shadow: 0px 0px;
			background-color: #ffc7d0;
		}
	}
`
