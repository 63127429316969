import styled from "styled-components/macro"
import WordCandy from "../types/WordCandy"
import ExpandableCard from "../atoms/ExpandableCard"

type WordCandyProps = {
	items: WordCandy[]
}

const WordCandyGrid: React.FC<WordCandyProps> = ({ items }) => {
	return (
		<Wrapper>
			{items.map(item => (
				<ExpandableCard header={item.phrase} icon="&#127852;">
					<SectionHeader>Example</SectionHeader>
					<br />
					<Section>{item.example}</Section>
					<br />
					<SectionHeader>Description</SectionHeader>
					<br />
					<Section>{item.description}</Section>
				</ExpandableCard>
			))}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	padding: 10px;
`
const Section = styled.div``
const SectionHeader = styled.h3``

export default WordCandyGrid
