import styled from "styled-components"
type CopProps = {
	question?: string
}

const AreYouACop: React.FC<CopProps> = ({ question }) => {
	return (
		<CopForm>
			<CopLabel>
				<b> {question || "are you a cop? "}</b>
			</CopLabel>
			<CopInput type="radio" id="yes" name="cop" value="yes"></CopInput>
			<CopLabel htmlFor="yes"> yes </CopLabel>
			<CopInput type="radio" id="no" name="cop" value="no"></CopInput>
			<CopLabel htmlFor="no"> no</CopLabel>
		</CopForm>
	)
}

const CopForm = styled.form``
const CopLabel = styled.label``
const CopInput = styled.input``

export default AreYouACop
