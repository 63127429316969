import styled from "styled-components"

const StyledSelect = styled.select`
	max-width: 50%;
	height: 100%;
	padding: 0.5rem;
	margin-bottom: 1rem;
`

const StyledOption = styled.option`
	color: ${props => (props.selected ? "lightgrey" : "black")};
`

const StyledLabel = styled.label`
	margin-bottom: 1rem;
`

const DropdownWrapper = styled.form`
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
`

export { StyledLabel, StyledOption, StyledSelect, DropdownWrapper }
