import React from "react"
import Project from "../types/Project"
import ProjectsGrid from "../molecules/ProjectsGrid"

export const tempProjects: Project[] = [
	{
		title: "spanish fluency in 3 months",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string",
		content: "",
		type: "project",
	},
	{
		title: "jomo: dispensary management software",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string1",
		content: "",
		type: "project",
	},
	{
		title: "the citadel of ricks",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string2",
		content: "",
		type: "project",
	},
	{
		title: "how to grow 3lbs/light",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string3",
		content: "",
		type: "project",
	},

	{
		title: "10 day water fast",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string4",
		content: "",
		type: "project",
	},

	{
		title: "strength training, 12lbs of muscle in 4 weeks",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string5",
		content: "",
		type: "project",
	},

	{
		title: "build anonymous software application on the web",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string6",
		content: "",
		type: "project",
	},

	{
		title: "BuenosAires Architect Designing",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string7",
		content: "",
		type: "project",
	},

	{
		title: "Build your own cold plunge",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string8",
		content: "",
		type: "project",
	},

	{
		title: "Nutritional Case Study",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string9",
		content: "",
		type: "project",
	},
	{
		title: "citadel of ricks",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string10",
		content: "",
		type: "project",
	},
	{
		title: "PhDB",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string11",
		content: "",
		type: "project",
	},

	{
		title: "Quotes",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string12",
		content: "",
		type: "project",
	},

	{
		title: "BookNotes",
		description: "this is a ",
		descriptionTeaser: "this is thee 50 character p tag description teaser",
		_id: "2",
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		tag: ["jawn", "otherJawnTag"],
		imageURL: "https://placehold.co/150x100",
		statusTag: [""],
		openGraphDescription: "string",
		openGraphImage: "string",
		slug: "string13",
		content: "",
		type: "project",
	},
]

const Projects: React.FC = () => {
	return (
		<div>
			<h1>this is projects header</h1>
			<p>this is projects descriptor</p>
			<ProjectsGrid items={tempProjects} />
		</div>
	)
}

export default Projects
