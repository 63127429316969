import styled from "styled-components/macro"

const Footer = styled.div`
	letter-spacing: 10px;
	text-align: center;
	margin-top: 20px;
	padding: 2px;
	position: relative;
	bottom: 0;
	width: 100%;
`

export default Footer
