import React from "react"

import NavBar from "./organisms/NavBar"
import styled, { createGlobalStyle } from "styled-components/macro"
//^^does this need to be macro
import { NavLink, Outlet } from "react-router-dom"
import Footer from "./atoms/Footer"
import LineSlide from "./molecules/LineSlide"
//^^this renders the child route's elements in the router created by
//createBrowserRouter from react-router-dom do i need NavLink in here? bc the
//NavBar component requires it?

//function of the root.tsx, is the "home page", used as homebase for express
//routing, all other routes are children of this, it is the domain name + / it
//is the first element rendered in the router in index.tsx file, all other
//routes are it's children createGlobalStyle function allows us to access &
//globally apply the variables we set in Theme to specific elements, similar to
//styles.css but  w/ dynamic variables

const GlobalStyle = createGlobalStyle`
	*{
		box-sizing: border-box;
		padding: 0; 
		margin: 0;
		scrollbar-color: white transparent;
	}
	*::-webkit-scrollbar {
		margin-top: 10px;
     width: 12px ;
}
*::-webkit-scrollbar-track {
	
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;
  
}
	
    html,body{
        height: 100vh;
        background-color: ${({ theme }) => theme.colors.wrapperBg};
        #root{height:100%;}
		color: ${({ theme }) => theme.timeOfDayColor()}
    }
*:active, 
*:focus {
outline: none;
}
a{
	text-decoration: none;

}
`

const Root: React.FC = () => {
	return (
		<RootWrapper>
			<GlobalStyle />
			<NavBar />
			<ContentWrapper>
				<Outlet />
			</ContentWrapper>
			<Footer>
				created by <a href="/about">rick</a>
			</Footer>
		</RootWrapper>
	)
}
const RootWrapper = styled.div`
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	@media (min-width: 850px) {
		max-width: 850px;
		margin: 0 auto;
	}
`
const ContentWrapper = styled.div`
	display: flex;
	flex: 1;
	@media (max-width: 500px) {
	}
`
//set to 850px so it can fit 3 250px width cards in it

export default Root

/*the hierarchy of style based component rendering seems to be
1. theme - where the actual values & property names are specified
2. wrapper - parent div for flexbox styling
3. GlobalStyle - the const that we can dynamically pass through the style values
   from theme
4. Content/NavWrapper - 2 children of the flex box, defines the layout of the
   screen, divvied up into two main sections
5. the actual content / NavBar - their individual components will define layout
   relative to the content/navWrapper parents & other components

is there any redundancy happening here?
*/
