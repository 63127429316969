import React from "react"
import BookNote from "../types/BookNote"
import BookNotesGrid from "../molecules/BookNotesGrid"
import PageDescriptor from "../atoms/PageDescriptor"
import styled from "styled-components"
import StickyHeaderWrapper from "../atoms/StickyHeaderWrapper"
import ToggleSlider from "../molecules/ToggleSlider"
import InfiniteMarquee from "../molecules/InfiniteMarquee"
import Card from "../atoms/Card"
import CardLink from "../atoms/CardLink"
import CardImage from "../atoms/CardImage"
import CardSubDescription from "../atoms/CardSubDescription"
import CardDescription from "../atoms/CardDescription"
import CardFooter from "../atoms/CardFooter"
import SubTagChip from "../atoms/SubTagChip"
//import useBookNotes from "../hooks/useBookNotes"
//will likely need to add a field for an array of quotes, phDB, marginNotes/ideas, usefulInfo(wordForWord instruction/info from book usually) etc. from the book
//also something to encapsulate some formatting like making headers, & structure, callouts, etc, for the actual bookNote page

export const tempNotes: BookNote[] = [
	{
		title: "Journey to the end of the night",
		description:
			"french guy joins WWI as a joke, then got banished to northern africa to work as a slave, autobiography",
		descriptionTeaser:
			"french guy joins WWI as a joke, then got banished to northern africa to work as a slave, autobiography",
		_id: "2",
		isbn: "isbn#",
		rating: 9,
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		author: "Ferdinand Celine",
		tag: [
			{
				label: "jawn",
				color: "green",
			},
		],
		imageURL: "https://placehold.co/150x100",
		openGraphDescription: "string",
		content: "Lorem ipsum blah blah blah",
		openGraphImage: "",
		slug: "slugone",
		headers: ["chapter1", "chapter2"],
		type: "booknote",
	},
	{
		title: "blah1",
		description: "yadda",
		descriptionTeaser: "this is the truncated version overview of what to expect",
		_id: "1",
		rating: 7,
		datePosted: "datePosted",
		link: "https://www.rickcarabba.com",
		isbn: "isbn#",
		author: "johnson",
		tag: [
			{
				label: "jawn",
				color: "green",
			},
			{
				label: "jawn2",
				color: "red",
			},
		],
		imageURL: "https://placehold.co/150x100",
		openGraphDescription: "string",
		content: "",
		openGraphImage: "",
		slug: "slugtwo",
		headers: ["chapter1", "chapter2"],
		type: "booknote",
	},
	{
		title: "blah3",
		description: "yadda",
		descriptionTeaser: "this is the truncated version overview of what to expect",
		isbn: "isbn#",
		_id: "3",
		datePosted: "datePosted",
		rating: 7,
		link: "https://www.rickcarabba.com",
		author: "johnson",
		slug: "slugthree",
		tag: [
			{
				label: "jawn",
				color: "green",
			},
		],
		imageURL: "https://placehold.co/150x100",
		openGraphDescription: "string",
		content: "",
		openGraphImage: "",
		headers: ["chapter1", "chapter2"],
		type: "booknote",
	},
	{
		title: "blah2",
		description: "yadda1",
		descriptionTeaser: "this is the truncated version overview of what to expect",
		isbn: "isbn#",
		_id: "6",
		rating: 7,
		datePosted: "datePosted",
		openGraphDescription: "string",
		link: "https://www.rickcarabba.com",
		author: "johnson",
		tag: [
			{
				label: "jawn",
				color: "green",
			},
		],
		imageURL: "https://placehold.co/150x100",
		content: "",
		openGraphImage: "",
		headers: ["chapter1", "chapter2"],
		type: "booknote",
		slug: "slug4",
	},
	{
		title: "blah1",
		description: "yadda",
		descriptionTeaser: "this is the truncated version overview of what to expect",
		isbn: "isbn#",
		_id: "4",
		datePosted: "datePosted",
		content: "",
		openGraphImage: "",
		openGraphDescription: "string",
		slug: "slug",
		rating: 7,
		headers: ["chapter1", "chapter2"],
		type: "booknote",
		link: "https://www.rickcarabba.com",
		author: "johnson",
		tag: [
			{
				label: "jawn",
				color: "green",
			},
		],
		imageURL: "https://placehold.co/150x100",
	},
	{
		title: "blah3",
		description: "yadda",
		descriptionTeaser: "this is the truncated version overview of what to expect",
		_id: "5",
		datePosted: "datePosted",
		openGraphDescription: "string",
		content: "",
		openGraphImage: "",
		isbn: "isbn#",
		rating: 7,
		author: "johnson",
		slug: "slug",
		headers: ["chapter1", "chapter2"],
		type: "booknote",
		link: "https://www.rickcarabba.com",
		tag: [
			{
				label: "jawn",
				color: "green",
			},
		],
		imageURL: "https://placehold.co/150x100",
	},
]

const BookNotes: React.FC = () => {
	//const { data } = useBookNotes()
	return (
		<Wrapper>
			<StickyHeaderWrapper>
				<PageTitle> Notes</PageTitle>
			</StickyHeaderWrapper>

			<Wrapper>
				<BookNotesGrid items={tempNotes} />
			</Wrapper>
		</Wrapper>
	)
}

export default BookNotes
const Wrapper = styled.div`
	height: 100%;
	width: 100%;
`
const PageTitle = styled.h3`
	margin: 4px;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	font-size: 18px;
	font-weight: 500;
	height: fit-content;
`
const ChipWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	justify-content: flex-end;
`
