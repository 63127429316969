import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { useMutation } from "react-query"
import Modal from "../atoms/Modal"
import styled from "styled-components/macro"
import ShareIcon from "../atoms/ShareIcon"

const Share: React.FC = () => {
	const [down, setDown] = useState(false)
	const [show, setShow] = useState(false)

	const onClick = () => {
		setShow(true)
	}
	const onClose = () => {
		setShow(false)
	}
	const handleSetDown = useCallback(() => setDown(false), [])
	useEffect(() => {
		document.body.addEventListener("mouseup", handleSetDown)
		return () => {
			document.body.removeEventListener("mouseup", handleSetDown)
		}
	}, [handleSetDown])

	return (
		<ShareWrapper>
			<ShareIcon
				down={down}
				onClick={onClick}
				onMouseDown={() => setDown(true)}
				onMouseUp={handleSetDown}
			/>
			<Modal show={show} onClose={onClose} id="shareForm">
				<ModalContentWrapper>
					<h1>header about this sharing</h1>
					<p>share via sms/email/link copy</p>
					<p>link fo each</p>
				</ModalContentWrapper>
			</Modal>
		</ShareWrapper>
	)
}
const ShareWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const ModalContentWrapper = styled.div`
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
`
export default Share
