import React from "react"
import SkewContent from "../atoms/SkewContent"
import styled from "styled-components/macro"
import Wrapper from "../atoms/Wrapper"
import InfiniteMarquee from "../molecules/InfiniteMarquee"
import MiniCard from "../atoms/MiniCard"
import SubTagChip from "../atoms/SubTagChip"
import AnchorLink from "../atoms/AnchorLink"

const currentProjects = [
	{
		title: "BookNotes",
		image: "image 1",
		description: "description1",
		tag: "tag",
		tagColor: "tagcolor",
		link: "/booknotes",
	},
	{
		title: "QuotesDB",
		image: "image 2",
		description: "description2",
		tag: "tag",
		tagColor: "tagcolor",
		link: "/quotes",
	},
	{
		title: "WordCandy DB",
		image: "image 3",
		description: "description3",
		tag: "tag",
		tagColor: "tagColor",
		link: "/wordcandy",
	},
	{
		title: "JOMO",
		image: "image 3",
		description: "description3",
		tag: "tag",
		tagColor: "tagColor",
		link: "https://planetjomo.com",
	},
	{
		title: "kollektiv.haus",
		image: "image 3",
		description: "description3",
		tag: "tag",
		tagColor: "tagColor",
		link: "https://kollektiv.haus",
	},
	{
		title: "rick.xyz admin dashboardt",
		image: "image 3",
		description: "description3",
		tag: "tag",
		tagColor: "tagColor",
		link: "/secrets",
	},
]

const randomAngle = () => {
	var randomInteger = Math.floor(Math.random() * 25 - 12)

	return randomInteger + "deg"
}

const Home: React.FC = () => {
	const upcomingProjects = [
		{
			title: "title",
			image: "image 1",
			description: "description1",
			tag: "tag",
		},
		{
			title: "title2",
			image: "image 2",
			description: "description2",
			tag: "tag",
		},
		{
			title: "title3",
			image: "image 3",
			description: "description3",
			tag: "tag",
		},
	]

	const test = randomAngle()

	console.log(test)

	return (
		<Wrapper>
			<h1>Current Happenings</h1>
			<InfiniteMarquee speed="60s">
				{currentProjects.map(item => (
					<SkewContent angle={randomAngle()}>
						<AnchorLink href={item.link} target="_blank">
							<MiniCard hoverBg="powderblue">
								<h3> {item.title}</h3>
								<CardDescription>{item.description}</CardDescription>
								<SubTagChip> that </SubTagChip>
							</MiniCard>
						</AnchorLink>
					</SkewContent>
				))}
			</InfiniteMarquee>
			<h1>Upcoming Happenings</h1>
			<InfiniteMarquee direction="reverse">
				{upcomingProjects.map(item => (
					<SkewContent angle={randomAngle()}>
						<MiniCard>
							<h3> {item.title}</h3>
							<CardDescription>{item.description}</CardDescription>
						</MiniCard>
					</SkewContent>
				))}
			</InfiniteMarquee>

			<HomeWrapper>
				<br />
				<p>updates pushed here ~daily~</p>
				<p>putz around</p>
			</HomeWrapper>
		</Wrapper>
	)
}

export default Home

const CardDescription = styled.div`
	padding: 0px 7px;
	overflow: auto;
`

const HomeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
