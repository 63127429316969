import styled, { css } from "styled-components/macro"

const ShareIcon = ({ down, ...props }: React.SVGProps<SVGSVGElement> & { down: boolean }) => (
	<ShareIconWrapper down={down}>
		<svg
			id="fi_2958783"
			enable-background="new 0 0 512.231 512.231"
			height="23"
			fill="black"
			viewBox="0 0 512.231 512.231"
			width="23"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g>
				<path d="m271.249 121.448c-70.515 3.65-136.443 33.004-187.025 83.587-54.152 54.151-83.975 125.899-83.975 202.025v105.171l38.098-87.825c44.499-88.669 134.739-147.458 232.902-152.927v121.202l240.733-196.638-240.733-196.043zm30 29.612v-87.94l163.267 132.958-163.267 133.361v-88.379h-15c-56.878 0-112.732 15.993-161.522 46.249-36.948 22.913-68.428 53.121-92.593 88.604 15.408-126.529 123.493-224.853 254.115-224.853z"></path>
			</g>
		</svg>
	</ShareIconWrapper>
)

const ShareIconWrapper = styled.div<{ down: boolean }>`
	border: 1.5px solid black;
	margin: 5px;
	background-color: white;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	padding: 3.5px;
	height: min-content;
	width: min-content;
	box-shadow: -1.5px 3px black;
	transition: all 150ms ease-in-out;
	${({ down }) =>
		down &&
		css`
			transform: translate(-2px, 4px);
			box-shadow: 0px 0px;
			background-color: white;
		`}
	.modal {
		margin: 10px;
	}
	@media (max-width: 700px) {
		:hover,
		:active {
			transform: translate(-2px, 4px);
			box-shadow: 0px 0px;
			background-color: white;
		}
	}
`

export default ShareIcon
