import styled from "styled-components"

const Button = styled.button`
	text-transform: capitalize;
	margin-top: 10px;
	padding: 5px;
	background-color: lightgreen;
	box-shadow: -2px 2px black;
	border: 1.5px solid black;
	transition: all 200ms ease-in-out;
	&:hover,
	:active {
		cursor: pointer;
		box-shadow: -4px 4px black;
	}
`

export default Button
