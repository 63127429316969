import React, { FormEvent, useCallback, useReducer } from "react"
import Quote from "../types/Quote"
import { useMutation } from "react-query"
import axios from "axios"

const reducer = (previousState: Partial<Quote>, action: Partial<Quote>) => {
	return {
		...previousState,
		...action,
	}
}

const fields = ["title", "whoSaid", "description", "whereFrom", "timeSaid"] as const

const QuoteForm = () => {
	const [formData, dispatch] = useReducer(reducer, {})
	console.log({ formData })

	const {
		mutate: createQuote,
		isError,
		isSuccess,
		isLoading,
	} = useMutation((variables: Partial<Quote>) =>
		axios.post("/entries/quote", variables, { baseURL: process.env.REACT_APP_API_URL }),
	)

	const handleSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault()
			createQuote(formData)
		},
		[createQuote, formData],
	)

	return (
		<form onSubmit={handleSubmit}>
			{fields.map(key => (
				<input
					value={formData[key]}
					onChange={e => dispatch({ [key]: e.target.value })}
					type="text"
					key={key}
					placeholder={key}
				/>
			))}
			<button type="submit">button</button>
		</form>
	)
}

export default QuoteForm
