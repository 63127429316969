import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { useMutation } from "react-query"
import axios from "axios"
import Modal from "../atoms/Modal"
import SubscribeIcon from "../atoms/SubscribeIcon"
import styled from "styled-components/macro"
import ErrorMessage from "../atoms/ErrorMessage"
import SuccessMessage from "../atoms/SuccessMessage"
import InputWithSubmit from "./InputWithSubmit"
import AreYouACop from "../atoms/AreYouACop"

//there's 1 subscribe const that has 3 const inside, 1 uses state, 1 uses mutation, 1 handles submit, then there's a return function
//that will return a form
//step 1: create subscribe function & set state
//step 2: return the form and translate those values using state to the object input as the value and the onchange
//step 3: create the useMutation const, set the field on success,
// step4: create the handleSubmit logic,  use a isSuccess & isError, mutate the subscribe function, async post requst using axios, name the variables, set the endpoint

const Subscribe: React.FC = () => {
	const [email, setEmail] = useState("")
	const [down, setDown] = useState(false)
	const [show, setShow] = useState(false)

	const {
		mutate: subscribe,
		isError,
		isSuccess,
		isLoading,
	} = useMutation(
		(variables: { email: string }) =>
			axios.post("/subscribe", variables, { baseURL: process.env.REACT_APP_API_URL }),
		{
			onSuccess: () => {
				setEmail("")
				setShow(false)
			},
		},
	)
	const handleSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault()
			subscribe({ email })
		},
		[email, subscribe],
	)

	const onClick = () => {
		setShow(true)
	}
	const onClose = () => {
		setShow(false)
	}
	const handleSetDown = useCallback(() => setDown(false), [])
	useEffect(() => {
		document.body.addEventListener("mouseup", handleSetDown)
		return () => {
			document.body.removeEventListener("mouseup", handleSetDown)
		}
	}, [handleSetDown])

	return (
		<SubscribeWrapper>
			<SubscribeIcon
				down={down}
				onClick={onClick}
				onMouseDown={() => setDown(true)}
				onMouseUp={handleSetDown}
			/>
			<Modal show={show} onClose={onClose} id="subscribeForm">
				<ModalContentWrapper onSubmit={handleSubmit}>
					<h1>header about this subscribe</h1>
					<p>subtext about this subscribe</p>
					<p>here's a grid of the things you're subscribing 2</p>

					<InputWithSubmit
						value={email}
						onChange={e => setEmail(e.target.value)}
						type="email"
						autoComplete="email"
						placeholder="e m a i l"
						isLoading={isLoading}
					/>

					<AreYouACop question="but fr, u a cop doe? " />

					{isError && <ErrorMessage />}
					{isSuccess && <SuccessMessage />}
				</ModalContentWrapper>
			</Modal>
		</SubscribeWrapper>
	)
}

const SubscribeWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const ModalContentWrapper = styled.div`
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
`

export default Subscribe
