import styled from "styled-components"

const StickyHeaderWrapper = styled.div`
	position: sticky;
	background-color: white;
	border: 2px solid black;
	box-shadow: 1px 1px black;
	border-radius: 3px;
	border-bottom-right-radius: 20px;
	top: 75px;
	z-index: 1;
`

export default StickyHeaderWrapper
