import React from "react"
import QuotesGrid from "../molecules/QuotesGrid"
import Quote from "../types/Quote"

const tempQuotes: Quote[] = [
	{
		title: "header 1",
		tag: ["tag1"],
		whoSaid: "author",
		link: "string",
		datePosted: "string",
		_id: "string",
		description: "string",
		whereFrom: "string",
		timeSaid: "string",
		bookNoteId: "string",
	},
	{
		title: "header 1",
		tag: ["tag1"],
		whoSaid: "author",
		link: "string",
		datePosted: "string",
		_id: "string",
		description: "string",
		whereFrom: "string",
		timeSaid: "string",
		bookNoteId: "string",
	},
	{
		title: "header 1",
		tag: ["tag1"],
		whoSaid: "author",
		link: "string",
		datePosted: "string",
		_id: "string",
		description: "string",
		whereFrom: "string",
		timeSaid: "string",
		bookNoteId: "string",
	},
	{
		title: "header 1",
		tag: ["tag1"],
		whoSaid: "author",
		link: "string",
		datePosted: "string",
		_id: "string",
		description: "string",
		whereFrom: "string",
		timeSaid: "string",
		bookNoteId: "string",
	},
	{
		title: "header 1",
		tag: ["tag1"],
		whoSaid: "author",
		link: "string",
		datePosted: "string",
		_id: "string",
		description: "string",
		whereFrom: "string",
		timeSaid: "string",
		bookNoteId: "string",
	},
	{
		title: "header 1",
		tag: ["tag1"],
		whoSaid: "author",
		link: "string",
		datePosted: "string",
		_id: "string",
		description: "string",
		whereFrom: "string",
		timeSaid: "string",
		bookNoteId: "string",
	},
]

const Quotes: React.FC = () => {
	return (
		<div>
			<h1>Quotes Project</h1>
			<p>a collection of fav quotes, searchable & filterable</p>
			<p>search by mood/author/text/era/book </p>
			<p>
				when a mood button is clicked it shows 1 random quote from that mood tag, can then
				swipe/scroll to next or previous
			</p>
			<p>
				on the quote card there's the author, description, quote, mood, & flip button on the front
				side. <br />
				on the back side there's the time quote was said, the source, the book it came from (if
				applicable), the date it was added, and flip button,{" "}
			</p>

			<QuotesGrid items={tempQuotes} />
		</div>
	)
}

export default Quotes
