import styled from "styled-components/macro"
import BackArrow from "../atoms/BackArrow"

interface ToggleSliderProps {
	backgroundColor?: string
	content: Array<{ hName: string; content: JSX.Element | string }>
	selectedIndex: number
	setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
	title: string
}

const ToggleSlider: React.FC<ToggleSliderProps> = ({
	backgroundColor,
	content,
	selectedIndex,
	setSelectedIndex,
	title,
}) => {
	return (
		<StickyHeaderWrapperNew>
			<ToggleHeaderContainer>
				<BackArrow />
				<GridTitle>{title}</GridTitle>
			</ToggleHeaderContainer>
			<ToggleSliderWrapper>
				<ToggleContainer>
					{content.map((item, index) => {
						return (
							<ToggleContent
								key={item.hName}
								backgroundColor={backgroundColor}
								onClick={() => setSelectedIndex(index)}
								className={selectedIndex === index ? "selected" : ""}
							>
								{item.hName}
							</ToggleContent>
						)
					})}
				</ToggleContainer>
			</ToggleSliderWrapper>
		</StickyHeaderWrapperNew>
	)
}
const StickyHeaderWrapperNew = styled.div`
	width: 100%;
	position: sticky;
	background-color: white;
	border: 2px solid black;
	box-shadow: 1px 1px black;
	border-radius: 3px;
	border-bottom-right-radius: 20px;
	top: 75px;
	z-index: 1;
`
const ToggleContainer = styled.div`
	display: flex;

	background-color: white;
	border-bottom-right-radius: 16px;

	border-top: 2px solid black;
	margin: 0px 0px;
`

const ToggleHeaderContainer = styled.div`
	display: flex;
	height: fit-content;
	align-items: center;
`

const GridTitle = styled.h3`
	background-color: white;
	border: solid black 2px;
	border-radius: 3px;
	border-bottom-right-radius: 17px;
	padding: 8px;
	margin: 8px;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	font-size: 18px;
	font-weight: 500;
	height: fit-content;
	flex: 1;
`

const border = "2px solid black"

const ToggleContent = styled.div<{
	backgroundColor?: string
}>`
	flex: 1;
	text-align: center;
	border-radius: 1px;
	padding: 8px;
	letter-spacing: 2px;
	cursor: pointer;

	:hover,
	&.selected,
	:active {
		background-color: ${props => props.backgroundColor || "pink"};
		border-left: ${border};
		border-right: ${border};
		border-radius: 3px;

		&:first-child {
			border-left: none;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&:last-child {
			border-right: none;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 16px;
		}
	}
`

const ToggleSliderWrapper = styled.div``

export default ToggleSlider
