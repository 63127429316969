import styled from "styled-components/macro"

type ArrowButtonProps = {
	buttonColor?: string
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ buttonColor }) => {
	return (
		<ArrowButtonWrapper>
			<ArrowButtonSVG
				viewBox="0 0 55 37"
				stroke="#000"
				stroke-width="1.5"
				buttonColor={buttonColor}
			>
				<path d="m 40.26 2.429 l -0.395 -0.622 l -0.629 0.384 l -5.292 3.232 l -0.652 0.399 l 0.41 0.644 l 1.65 2.593 c 1.416 2.227 3.114 4.267 4.774 6.02 H 1.196 v 7.389 H 39.84 c -1.6 1.735 -3.212 3.783 -4.565 6.103 l -1.546 2.653 l -0.385 0.66 l 0.666 0.373 l 5.415 3.03 l 0.643 0.36 l 0.372 -0.637 l 1.546 -2.653 c 1.812 -3.108 4.34 -5.722 6.494 -7.594 a 37.774 37.774 0 0 1 2.67 -2.122 c 0.333 -0.239 0.594 -0.417 0.765 -0.532 l -0.416 -0.623 l 0.416 0.623 l 0.185 -0.121 l 0.036 -0.023 l 0.001 -0.001 l 0.002 -0.002 a 3.793 3.793 0 0 0 1.79 -3.099 a 3.785 3.785 0 0 0 -1.608 -3.2 l -0.003 -0.002 l -0.002 -0.002 l -0.044 -0.031 l -0.2 -0.147 c -0.181 -0.134 -0.453 -0.34 -0.797 -0.609 a 52.528 52.528 0 0 1 -2.743 -2.31 c -2.212 -1.993 -4.788 -4.656 -6.623 -7.54 L 40.26 2.429 Z"></path>
			</ArrowButtonSVG>
		</ArrowButtonWrapper>
	)
}

const ArrowButtonWrapper = styled.button`
	background: white;
	border: none;
	border-bottom-right-radius: 10px;
	border-top-right-radius: 10px;
	height: 45px;
	width: 45px;
	padding: 5px;
	display: flex;
	align-items: center;
`

const ArrowButtonSVG = styled.svg<{ buttonColor?: string }>`
	filter: drop-shadow(-1px 1px black);
	height: 100%;
	width: 100%;
	padding-left: 2px;
	fill: ${props => props.buttonColor || "pink"};
	transition: all 150ms ease-in-out;
	&:hover,
	:active {
		cursor: pointer;
		transform: translateX(10px);
		filter: drop-shadow(-3px 1px black);
	}
`

export default ArrowButton
