import React from "react"
import BookNote from "../types/BookNote"
import SectionHeader from "../styled-components/SectionHeader"
import styled from "styled-components"

type BookNoteProps = {
	item: BookNote
}
const TOC: React.FC<BookNoteProps> = ({ item }) => {
	return (
		<TOCWrapper>
			<h1>Table of Contents</h1>
			<p>these will be mapped</p>
			<SectionHeader>Chapter Header 1</SectionHeader>
		</TOCWrapper>
	)
}

const TOCWrapper = styled.div``

export default TOC
